export const fetchTypes = {
    WORKS: 'WORKS',
    ALL: 'ALL',
    AUTHORS: 'AUTHORS',
    SUBJECTS: 'SUBJECTS',
    PLACES: 'PLACES',
    RESULTS: 'RESULTS',
    ID: 'ID',
    PAGES: 'PAGES',
    POSTS: 'POSTS',
    GS: 'GS',
}

export const status = {
    START: 'START',
    CLEAR: 'CLEAR',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
    REQUEST: 'REQUEST',
    RECEIVE: 'RECEIVE',
    ERROR: 'ERROR',
}

export const actions = {
    SET_LANG: 'SET_LANG',
    SET_PAGE: 'SET_PAGE',
    SET_COLLAPSE: 'SET_COLLAPSE',
    SET_MENU: 'SET_MENU',
    SET_BROWSE: 'SET_BROWSE',
    SET_COLLECTION: 'SET_COLLECTION',
    SET_OFFSETS: 'SET_OFFSETS',
    SET_MODAL: 'SET_MODAL',
    RESET_OFFSETS: 'RESET_OFFSETS',
    CLEAR_RESULTS: 'CLEAR_RESULTS',
    CLEAR_FILTER_TERM: 'CLEAR_FILTER_TERM',
    CLEAR_FILTER_ARRAY: 'CLEAR_FILTER_ARRAY',
    DELETE_FULL_TEXT: 'DELETE_FULL_TEXT',
    REQUEST_PAGES: 'REQUEST_PAGES',
    RECEIVE_PAGES: 'RECEIVE_PAGES',
    REQUEST_POSTS: 'REQUEST_POSTS',
    RECEIVE_POSTS: 'RECEIVE_POSTS',
    REQUEST_GS: 'REQUEST_GS',
    RECEIVE_GS: 'RECEIVE_GS',
    REQUEST_WORKS: 'REQUEST_WORKS',
    RECEIVE_WORKS: 'RECEIVE_WORKS',
    REQUEST_ALL: 'REQUEST_ALL',
    RECEIVE_ALL: 'RECEIVE_ALL',
    REQUEST_AUTHORS: 'REQUEST_AUTHORS',
    RECEIVE_AUTHORS: 'RECEIVE_AUTHORS',
    REQUEST_SUBJECTS: 'REQUEST_SUBJECTS',
    RECEIVE_SUBJECTS: 'RECEIVE_SUBJECTS',
    REQUEST_PLACES: 'REQUEST_PLACES',
    RECEIVE_PLACES: 'RECEIVE_PLACES',
    REQUEST_ID: 'REQUEST_ID',
    RECEIVE_ID: 'RECEIVE_ID',
    CLEAR_ID: 'CLEAR_ID',
    REQUEST_RESULTS: 'REQUEST_RESULTS',
    RECEIVE_RESULTS: 'RECEIVE_RESULTS',
    ADD_TERM_TO_HISTORY: 'ADD_TERM_TO_HISTORY',
    ADD_TERM_TO_FILTER: 'ADD_TERM_TO_FILTER',
    SET_CURRENT_SEARCH_TERM: 'SET_CURRENT_SEARCH_TERM',
    SET_CURRENT_FILTER_TERM: 'SET_CURRENT_FILTER_TERM',
    SET_FILTER_ARRAY: 'SET_FILTER_ARRAY',
    REMOVE_FILTER: 'REMOVE_FILTER',
}
